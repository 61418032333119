<template>
  <v-app class="copilot">
    <v-app-bar color="var(--theme-identite)" elevation="0" style="z-index: 10 !important" :density="headerHeight">
      <div class="copilot-header d-flex mx-auto" :style="{height: '50px'}">
        <div v-if="isCredentials" class="d-flex justify-start align-center ml-5 mr-4">
          <v-btn icon color="var(--theme-icone)" @click="navigateTo('/vehicules')">
            <icon-switch_vehicule filled style="font-size: 30px" clickable />
          </v-btn>
        </div>
        <div v-else class="d-flex justify-start align-center ml-5 mr-4" style="min-width: 48px" />
        <div class="d-flex justify-center align-center header-logo">
          <icon-logo_copilot filled color="white" :style="{fontSize: logoSize}" class="logo" @click="navigateTo('/')" />
        </div>
        <div class="d-flex justify-end align-center ml-4 mr-5">
          <v-btn v-if="isCredentials" icon color="var(--theme-icone)" @click="navigateTo('/mon-compte')">
            <icon-account filled style="font-size: 25px" />
          </v-btn>
          <v-btn v-else icon color="var(--theme-icone)" @click="logout()">
            <icon-logout_fatec style="font-size: 25px" clickable />
          </v-btn>
        </div>
      </div>
    </v-app-bar>
    <v-main :style="{'padding-bottom': footerHeight + '!important'}">
      <div :class="{banner: !isOnHomePage()}" />
      <slot />
    </v-main>
    <v-bottom-navigation :style="{height: footerHeight}" style="z-index: 10 !important">
      <CopilotMenu />
    </v-bottom-navigation>
    <div v-if="loaderStore.isLoading()" class="progress-layout d-flex align-center justify-center">
      <v-progress-circular color="var(--theme-icone)" indeterminate />
    </div>
  </v-app>
</template>

<script lang="ts" setup>
import CopilotMenu from '~/components/ui/menu/menu.vue';

import {useDisplay} from 'vuetify';
const {name} = useDisplay();
const route = useRoute();
const authenticationStore = useAuthentificationStore();
const loaderStore = useLoaderStore();

const footerHeight = computed(() => {
  return name.value === 'xs' ? '60px' : '80px';
});

const headerHeight = computed(() => {
  return name.value === 'xs' ? 'comfortable' : '';
});

const logoSize = computed(() => {
  return name.value === 'xs' ? '110px' : '130px';
});

const isOnHomePage = () => {
  return route.path === '/';
};

const isCredentials = computed(() => {
  return authenticationStore.getModeConnexion() === 'credentials';
});

const logout = () => {
  authenticationStore.logout();
};
</script>

<style scoped>
.copilot {
  background: none !important;
}

.copilot-header {
  max-width: 700px;
  width: 100%;
  display: flex;
}

.header-logo {
  width: 100%;
}

.logo:hover {
  cursor: pointer;
}

.banner {
  position: absolute;
  height: 150px;
  width: 100%;
  background-color: var(--theme-identite-2);
  z-index: -1;
}
</style>
